
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const FeedBack = () => {
  const [error, setError] = useState({
    firstname: '',
    location: '',
    email: '',
    message: '',
    rating: '',
  });

  const showAlert = () => {
    Swal.fire({
      title: 'SUCCESS',
      text: 'Feedback Submitted successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const validation = () => {
    let isValid = true;
    const newError = { firstname: '', location: '', email: '', message: '', rating: '' };

    const nameRegex = /^[a-zA-Z]{3,}$/;
    if (!formData.firstname.trim() || !nameRegex.test(formData.firstname.trim())) {
      newError.firstname = 'First Name is required';
      isValid = false;
    }

    if (!formData.location) {
      newError.location = 'Location is required';
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      newError.email = 'Email is required';
      isValid = false;
    }

    if (!formData.message.trim()) {
      newError.message = 'Message is required';
      isValid = false;
    }

    if (rating === 0) {
      newError.rating = 'Rating is required';
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  const [rating, setRating] = useState(0);
  const [formData, setFormData] = useState({
    firstname: '',
    location: '',
    email: '',
    message: '',
    rating: 0,
  });

  const handleRatingChange = (value) => {
    setRating(value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validation()) {
      return;
    }

    console.log(formData.firstname);
    console.log(formData.message);
    console.log(formData.location);
    console.log(formData.email);
    console.log(rating);

    showAlert();
    // await axios.post('https://dv-homes-front-beryl.vercel.app/api/testimonial', {
 // await axios.post('http://localhost:3000/api/testimonial',  {
    await axios.post('https://dv-homes-front.vercel.app/api/testimonial', {
      
      customerName: formData.firstname,
      description: formData.message,
      location: formData.location,
      email: formData.email,
      rating: rating,
    });

    setFormData({
      firstname: '',
      location: '',
      email: '',
      message: '',
      rating: 0,
    });

    // Reset form fields
    setRating(0);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll to the top smoothly when the FeedBack component mounts
    });
  }, []);

  return (
    <div className="flex justify-center items-center">
      <div className="gap-5 flex max-md:flex-col mt-32 max-md:items-stretch max-md:gap-0">
        <div className="flex flex-col items-stretch w-[48%] max-md:w-full max-md:ml-0">
          <div className="flex flex-col px-5 items-start max-md:max-w-full max-md:mt-10">
            <div className="text-primary1 text-5xl font-bold leading-[56.16px] self-stretch max-md:max-w-full max-md:text-4xl">Want to sumbit a feedback?</div>
            <div className="text-slate-900 text-lg leading-6 self-stretch mt-4 max-md:max-w-full">We’d love to hear from you.</div>
            <img loading="lazy" src="/img/review.svg" className="aspect-[1.95] object-contain object-center w-[467px] overflow-hidden max-w-full mt-16 max-md:mt-10" alt="dv" />
          </div>
        </div>
        <div className="flex flex-col items-stretch w-[52%] ml-5 max-md:w-full max-md:ml-0">
          <div className="items-start bg-stone-50 flex grow flex-col w-full p-6 rounded-lg max-md:max-w-full max-md:mt-10 max-md:px-5">
            <div className="text-slate-900 text-2xl font-bold leading-8 self-stretch max-md:max-w-full">Submit Review</div>
            <div className="text-slate-500 text-lg leading-6 self-stretch mt-2.5 max-md:max-w-full">Fill out the form !</div>
            {/* <div className='text-slate-500 text-lg leading-6 self-stretch mt-2.5 max-md:max-w-full'></div> */}
            <form onSubmit={handleSubmit}>
              <div className="items-stretch self-stretch flex justify-between gap-1 mt-6 max-md:max-w-full max-md:flex-wrap">
                <div className="items-stretch flex grow basis-[0%] flex-col">
                  <label htmlFor="firstname" className="text-slate-900 text-base leading-6">
                    First name
                  </label>
                  <input type="text" id="firstname" name="firstname" placeholder="Enter your first name" value={formData.firstname} onChange={handleInputChange} className=" text-base border  border-gray-300 p-2 leading-6 whitespace-nowrap justify-center  mt-2.5 w-full md:max-w-72 pr-16 py-3 rounded-lg items-start max-md:pr-5"></input>
                  <div className="text-rose-800">{error.firstname}</div>
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col">
                  <label htmlFor="lastname" className="text-slate-900 text-base leading-6">
                    Location
                  </label>
                  <input type="text" id="location" name="location" placeholder="Enter your city, state.." value={formData.location} onChange={handleInputChange} className=" text-base border   border-gray-300  p-2 leading-6 whitespace-nowrap justify-center  mt-2.5  w-full md:max-w-72 pr-16 py-3 rounded-lg items-start max-md:pr-5"></input>
                  <div className="text-rose-800">{error.location}</div>
                </div>
              </div>
              <div className="mb-6">
                <label htmlFor="email" className="block text-slate-900 text-base p-2 leading-6">
                  Email
                </label>
                <input type="mail" id="email" name="email" placeholder="Enter your email address" value={formData.email} onChange={handleInputChange} className="mt-1 p-3 border w-full  border-gray-300 rounded-md"></input>
                <div className="text-rose-800">{error.email}</div>
              </div>
              <div className="mb-6">
                <label htmlFor="message" className="block text-slate-900 text-base leading-6">
                  Message
                </label>
                <textarea id="message" name="message" placeholder="Enter your message" value={formData.message} onChange={handleInputChange} className="mt-1 p-3 w-full md:max-w-full border border-gray-300 rounded-md"></textarea>
                <div className="text-rose-800">{error.message}</div>
              </div>
              <div className="flex flex-wrap justify-center">
                {[1, 2, 3, 4, 5].map((value) => (
                  <span key={value} onClick={() => handleRatingChange(value)} className={`inline-block text-3xl sm:text-4xl md:text-5xl lg:text-6xl mx-1 md:mx-2 lg:mx-3 cursor-pointer ${value <= rating ? 'text-yellow-500' : 'text-gray-400'}`}>
                    &#9733;
                  </span>
                ))}
              </div>
              <div className="text-rose-800">{error.rating}</div>

              <button type="submit" className="text-zinc-50 text-base ml-40 leading-6 whitespace-nowrap justify-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 mt-6 px-6 py-4 rounded-[30px] self-center max-md:px-5">
                Submit Review
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedBack;



// import axios from 'axios';
// import React, { useEffect, useState, useRef } from 'react';
// import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';
// import TestimonialsCarousel from './TestimonialsCarousel';

// const FeedBack = () => {

//   const [error, setError] = useState({
//     firstname: '',
//     location: '',
//     email: '',
//     message: '',
//     rating: '',
//   });

//   const videoInputRef = useRef(null); // Create a ref for the video input

//   const showAlert = () => {
//     Swal.fire({
//       title: 'SUCCESS',
//       text: 'Feedback Submitted successfully!',
//       icon: 'success',
//       confirmButtonText: 'OK',
//     });
//   };

//   const validation = () => {
//     let isValid = true;
//     const newError = { firstname: '', location: '', email: '', message: '', rating: '' };

//     const nameRegex = /^[a-zA-Z]{3,}$/;
//     if (!formData.firstname.trim() || !nameRegex.test(formData.firstname.trim())) {
//       newError.firstname = 'First Name is required';
//       isValid = false;
//     }

//     if (!formData.location) {
//       newError.location = 'Location is required';
//       isValid = false;
//     }

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
//       newError.email = 'Email is required';
//       isValid = false;
//     }

//     if (!formData.message.trim()) {
//       newError.message = 'Message is required';
//       isValid = false;
//     }

//     if (rating === 0) {
//       newError.rating = 'Rating is required';
//       isValid = false;
//     }

//     setError(newError);
//     return isValid;
//   };

//   const [rating, setRating] = useState(0);
//   const [videoKey, setVideoKey] = useState(Date.now()); // Add a key for the video input field

//   const [formData, setFormData] = useState({
//     firstname: '',
//     location: '',
//     email: '',
//     message: '',
//     rating: 0,
//     video: null,
//   });

//   const handleRatingChange = (value) => {
//     setRating(value);
//   };

//   const handleFileChange = (event) => {
//     const { name, files } = event.target;
//     const file = files[0];

//     if(file && file.size > 100 * 1024 * 1024) {
//       Swal.fire('Error', 'File size exceeds 100 MB. Please upload a smaller file.', 'error');
//       if(videoInputRef.current) {
//         videoInputRef.current.value = '';
//       }
//       return;
//     }

//     setFormData({
//       ...formData,
//       [name]: files[0], // Set the file into the state
//     });
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     console.log("clicked")
//     // Validate the form data
//     if (!validation()) {
//       return;
//     }
  
//     // Create FormData object to send form data including file
//     const formDataToSubmit = new FormData();
  
//     // Append form data (text fields) to FormData
//     formDataToSubmit.append('customerName', formData.firstname);
//     formDataToSubmit.append('location', formData.location);
//     formDataToSubmit.append('email', formData.email);
//     formDataToSubmit.append('description', formData.message);
//     formDataToSubmit.append('rating', rating);
  
//     // If there's a video file selected, append it to FormData
//     if (formData.video) {
//       formDataToSubmit.append('video', formData.video);
//     }
  
//     try {
//       // Send the form data to the backend (including the video file)
//       // await axios.post('https://dv-homes-front.vercel.app/api/testimonial', formDataToSubmit, {
//         // await axios.post('http://localhost:3000/api/testimonial', formDataToSubmit, {
//         await axios.post('https://dv-homes-front-beryl.vercel.app/api/testimonial', formDataToSubmit, {

//         headers: {
//           'Content-Type': 'multipart/form-data', // Important to set the content type for file uploads
//         },
//       });
  
//       // Show success alert
//       showAlert();
  
//       // Reset form fields after successful submission
//       setFormData({
//         firstname: '',
//         location: '',
//         email: '',
//         message: '',
//         rating: 0,
//         video: null, // Reset video field
//       });

//       // Clear the video file input field
//       if (videoInputRef.current) { 
//         videoInputRef.current.value = ''; 
//       }
  
//       // Reset rating
//       setRating(0);
//       setVideoKey(Date.now());
  
//     } catch (error) {
//       console.error('Error submitting feedback:', error);
//       Swal.fire('Error', 'Something went wrong, please try again.', 'error');
//     }
//   };
  
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth', // Scroll to the top smoothly when the FeedBack component mounts
//     });
//   }, []);

//   return (
//     <div>
//       <div className="flex justify-center items-center">
//         <div className="gap-5 flex max-md:flex-col mt-32 max-md:items-stretch max-md:gap-0">
//           <div className="flex flex-col items-stretch w-[48%] max-md:w-full max-md:ml-0">
//             <div className="flex flex-col px-5 items-start max-md:max-w-full max-md:mt-10">
//               <div className="text-primary1 text-5xl font-bold leading-[56.16px] self-stretch max-md:max-w-full max-md:text-4xl">Want to sumbit a feedback?</div>
//               <div className="text-slate-900 text-lg leading-6 self-stretch mt-4 max-md:max-w-full">We’d love to hear from you. </div>
//               {/* <Link to={"/testimonials"}> View All Testimonials </Link> */}
//               <img loading="lazy" src="/img/review.svg" className="aspect-[1.95] object-contain object-center w-[467px] overflow-hidden max-w-full mt-16 max-md:mt-10" alt="dv" />
//             </div>
//           </div>
//           <div className="flex flex-col items-stretch w-[52%] ml-5 max-md:w-full max-md:ml-0">
//             <div className="items-start bg-stone-50 flex grow flex-col w-full p-6 rounded-lg max-md:max-w-full max-md:mt-10 max-md:px-5">
//               <div className="text-slate-900 text-2xl font-bold leading-8 self-stretch max-md:max-w-full">Submit Review</div>
//               <div className="text-slate-500 text-lg leading-6 self-stretch mt-2.5 max-md:max-w-full">Fill out the form !  </div>
//               {/* <div className='text-slate-500 text-lg leading-6 self-stretch mt-2.5 max-md:max-w-full'></div> */}
//               <form onSubmit={handleSubmit} encType="multipart/form-data">
//                 <div className="items-stretch self-stretch flex justify-between gap-1 mt-6 max-md:max-w-full max-md:flex-wrap">
//                   <div className="items-stretch flex grow basis-[0%] flex-col">
//                     <label htmlFor="firstname" className="text-slate-900 text-base leading-6">
//                       First name
//                     </label>
//                     <input type="text" id="firstname" name="firstname" placeholder="Enter your first name" value={formData.firstname} onChange={handleInputChange} className=" text-base border  border-gray-300 p-2 leading-6 whitespace-nowrap justify-center  mt-2.5 w-full md:max-w-72 pr-16 py-3 rounded-lg items-start max-md:pr-5"></input>
//                     <div className="text-rose-800">{error.firstname}</div>
//                   </div>
//                   <div className="items-stretch flex grow basis-[0%] flex-col">
//                     <label htmlFor="lastname" className="text-slate-900 text-base leading-6">
//                       Location
//                     </label>
//                     <input type="text" id="location" name="location" placeholder="Enter your city, state.." value={formData.location} onChange={handleInputChange} className=" text-base border   border-gray-300  p-2 leading-6 whitespace-nowrap justify-center  mt-2.5  w-full md:max-w-72 pr-16 py-3 rounded-lg items-start max-md:pr-5"></input>
//                     <div className="text-rose-800">{error.location}</div>
//                   </div>
//                 </div>
//                 <div className="mb-6">
//                   <label htmlFor="email" className="block text-slate-900 text-base p-2 leading-6">
//                     Email
//                   </label>
//                   <input type="mail" id="email" name="email" placeholder="Enter your email address" value={formData.email} onChange={handleInputChange} className="mt-1 p-3 border w-full  border-gray-300 rounded-md"></input>
//                   <div className="text-rose-800">{error.email}</div>
//                 </div>
//                 <div className="mb-6">
//                   <label htmlFor="message" className="block text-slate-900 text-base leading-6">
//                     Message
//                   </label>
//                   <textarea id="message" name="message" placeholder="Enter your message" value={formData.message} onChange={handleInputChange} className="mt-1 p-3 w-full md:max-w-full border border-gray-300 rounded-md"></textarea>
//                   <div className="text-rose-800">{error.message}</div>
//                 </div>
//                 <div className="mb-6">
//                   <label htmlFor="video" className="block text-slate-900 text-base leading-6">
//                     Upload Video (Optional)
//                   </label>
//                   <input key={videoKey} type="file" id="video" name="video" accept="video/*" onChange={handleFileChange} className="mt-1 p-3 w-full border border-gray-300 rounded-md" />
//                   {/* <input type="file" id="video" name="video" accept="video/*" onChange={handleFileChange} className="mt-1 p-3 w-full border border-gray-300 rounded-md" /> */}
//                   <div className="text-rose-800">{error.video}</div>
//                 </div>
//                 <div className="flex flex-wrap justify-center">
//                   {[1, 2, 3, 4, 5].map((value) => (
//                     <span key={value} onClick={() => handleRatingChange(value)} className={`inline-block text-3xl sm:text-4xl md:text-5xl lg:text-6xl mx-1 md:mx-2 lg:mx-3 cursor-pointer ${value <= rating ? 'text-yellow-500' : 'text-gray-400'}`}>
//                       &#9733;
//                     </span>
//                   ))}
//                 </div>
//                 <div className="text-rose-800">{error.rating}</div>

//                 <button type="submit" className="text-zinc-50 text-base ml-40 leading-6 whitespace-nowrap justify-center items-stretch transition duration-500 bg-primary1 hover:bg-secondary1 mt-6 px-6 py-4 rounded-[30px] self-center max-md:px-5">
//                   Submit Review
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//        <div className='py-8'>
//         <h2>WHAT PEOPLE SAY</h2>
//         <div>
//             <TestimonialsCarousel/>
//         </div>
//       </div> 
//     </div>
//   );
// };

// export default FeedBack;



  
